
import React, { useState } from "react";
import { TextField, Box, Stack, Button, Fab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Chip from "@mui/material/Chip";
import logo from "../../logo.svg";
import "./Sidebar.css";

export default function Sidebar({handleFileUpload, setSkills, fileLimit, clearAll}) {
    const [currentSkills, setCurrentSkills] = useState([]);
    const [skillLine, setSkillLine] = useState("")

    const handleOptionEnter = (event) => {
      if (event.keyCode == 13) {
          setCurrentSkills([ ...currentSkills, event.target.value ]);
          setSkills([ ...currentSkills, event.target.value]);
          setSkillLine("")   
      }
    }

    const handleOptionChange = (event) => {
      setSkillLine(event.target.value)
    }

    const handleOptionDelete = (value) => {
      setCurrentSkills(currentSkills.filter(option => option != value));
      setSkills(currentSkills.filter(option => option != value));
    }

    const handleClear = () => {
      clearAll()
      setSkills([])
      setCurrentSkills([])
    }

    return (
        <Box
        className="sidebar"
        sx={{
            width: "300px",
            textAlign: "center",
            padding: "15px",
            height: "100dvh"
        }}
        >
        <Stack spacing={3}>
          <Box sx={{marginBottom: "50px", marginTop: "15px"}}>
            <label htmlFor="upload-resume">
              <input
                id="upload-resume"
                name="upload-resume"
                style={{ display: 'none' }}
                multiple
                type="file"
                accept='application/pdf'
                onChange={handleFileUpload}
                disabled={fileLimit}
              />
              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
              >
                <AddIcon /> Upload Resumes
              </Fab>
            </label>
          </Box>
          <TextField 
            id="standard-basic" 
            label="Skills" 
            variant="standard" 
            onChange={handleOptionChange} 
            onKeyDown={handleOptionEnter}
            value={skillLine}
            helperText="Press Enter following each skill"
          />
          <Box>
            {currentSkills.map((skill) => (
                <Chip sx={{
                    height: 'auto',
                    '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                    },
                    margin: .2
                    }} color='primary' label={skill} key={skill} onDelete={()=>handleOptionDelete(skill)}/>
            ))}
          </Box>
          <Box sx={{marginTop: "10%"}}>
              <Button size="small" sx={{width: "75px"}} variant="contained" onClick={handleClear}> Clear </Button>
          </Box>
          <img src={logo} className="App-logo" alt="logo" />
          
        </Stack>
        </Box>
    )
}