import React, { useState } from "react";
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Tooltip from '@mui/material/Tooltip';
import { Chip } from "@mui/material";


function ResumeRow(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
            <TableCell>
                {(row.resumeName.endsWith('pdf') ) &&
                    <Tooltip title="Show Document" placement="left">
                        <IconButton
                        aria-label="expand row"
                        onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon fontSize="small"/> : <KeyboardArrowDownIcon fontSize="small"/>}
                        </IconButton>
                    </Tooltip>
                }
            </TableCell>
            <TableCell align="left"> {row.resumeName} </TableCell>
            <TableCell align="right">
                <>
                    {row.matchingSkills.length > 0 &&
                        row.matchingSkills.map((skill) => (
                            <Chip sx={{
                                height: 'auto',
                                '& .MuiChip-label': {
                                    display: 'block',
                                    whiteSpace: 'normal',
                                },
                                margin: .2
                                }} color='primary' key={skill} label={skill} />
                        ))
                    }
                </>
            </TableCell>
            <TableCell align="right">
                <>
                    {row.numMatches}
                </>
            </TableCell>
        </TableRow>
        <TableRow >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                
                    <object data={URL.createObjectURL(row.resume)} type="application/pdf" width="100%" height="700">
                        <embed src={URL.createObjectURL(row.resume)} type="application/pdf" width="100%" height="100%"/>
                    </object> 
                
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  export default ResumeRow;