import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Sidebar from "../../components/SideBar/Sidebar";
import ResumeTable from "../../components/ResumeTable/ResumeTable";

const pdfjsLib = require("pdfjs-dist");
pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.worker.min.js";


const Home = () => {
    const MAX_COUNT = 100
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [fileLimit, setFileLimit] = useState(false)
    const [skills, setSkills] = useState([])

    useEffect(() => {
        let updatedFiles = []
        uploadedFiles.map((file) => {
            let text2 = file.text
            text2 = text2.join(' ')
            text2 = text2.replace(/\s\s+/g, ' ');
            text2 = text2.toLowerCase();
            let skillsNew = []
            skills.map((skill) => {
                if (text2.includes(skill)) {
                    var re = new RegExp(skill, "g")

                    let skill_len = text2.match(re || []).length
                    skillsNew.push(skill + ", " + skill_len)
                }
            })
            file.matchingSkills = skillsNew
            file.numMatches = skillsNew.length
            updatedFiles.push(file)
        })
        setUploadedFiles(updatedFiles)
    }, [skills])

    const handleFileUpload = files => {
        const uploaded = [...uploadedFiles];
        let limitExceeded = false;

        files.map((file) => {
            let finalFile = {
                'resumeName' : file.name,
                'text': [],
                'resume': file,
                'matchingSkills': [],
                'numMatches': 0
            }
            if (uploaded.findIndex((f) => f.resumeName === file.name) === -1) {
                const loadingTask = pdfjsLib.getDocument(URL.createObjectURL(file));
                loadingTask.promise.then(function (pdfDocument) {
                    for (var i = 1; i <= pdfDocument.numPages; i++) {
                        pdfDocument.getPage(i).then(function (page) {
                            page.getTextContent().then(function (textContent) {
                                textContent.items.map((text) => {
                                    if (text.str !== "") {
                                        finalFile.text.push(text.str)
                                    }
                                })
                            })
                        })
                    }
                    
                })
                
                if (uploaded.length === MAX_COUNT) setFileLimit(true);
                if (uploaded.length > MAX_COUNT) {
                    alert(`you can only add a max of ${MAX_COUNT} files`);
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
                uploaded.push(finalFile)
            }
            
        })
        if (!limitExceeded) setUploadedFiles(uploaded)
    }

    const handleFileEvent = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleFileUpload(chosenFiles)
    }

    const clearAll = () => {
        setSkills([])
        setUploadedFiles([])
    }

    return (
        <div className="App" style={{ display: "flex" }}>
            <Sidebar
               handleFileUpload={handleFileEvent}
               setSkills={setSkills}
               fileLimit={fileLimit}
               clearAll={clearAll}
            />
            <div
                style={{
                    width: "70vw",
                    marginLeft: "25%",
                    marginTop: "2%",
                    position: "relative",
                }}
            >
                { uploadedFiles.length > 0 ?
                        <ResumeTable rows={uploadedFiles} />
                    :
                    
                    <Box sx={{marginTop: "20%"}}>
                        <Typography variant="h2" color="primary.contrastText">
                                Find the skills you need...
                        </Typography>
                        <Box sx={{width: "30%", marginTop: "3rem"}}>
                            <Stack spacing={3}>
                                <Typography variant="subtitle" color="primary.contrastText">
                                        Easily upload and search across many resumes for the desired skills, experience, and knowledge areas needed for your project. Quickly identify top candidates and seamlessly see each individual resume
                                </Typography>
                                <Typography variant="subtitle" color="primary.contrastText">
                                    Built by the AIX Discovery Lab
                                </Typography>
                            </Stack>
                           
                        </Box>
                    </Box>
                }
            </div>
        </div>
    );
}
export default Home;
